function Heading({title, tColor}){

    return(
        <h1 style={{color:{tColor}}}>{title}</h1>
    )

    
}


export default Heading