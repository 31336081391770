import './App.css';
import Card from './components/card.component';
import Heading from './components/Heading.component';
import KennethMelee from './Assets/KennethMelee.gif';
import ninja from './Assets/ninja.gif';
import Cassandra from './Assets/Cassandra.gif';
import fox from './Assets/fox.gif';
import kpu from './Assets/kpu_campus.jpg';
import UnityScreenShot from './Assets/UnityScreenShot.png';
import {
  Container,
  Paper,
  Grid,
} from '@material-ui/core';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          border: "none",
      }}
  />
);



function App() {
  
  return (
    <div style={{ backgroundColor: 	"#A7C7E7", paddingTop: 20 }}>
      <Container sx={{m:2}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}
              alignItems="center"
              justifyContent="center"
              >
          <Paper>
            <div className="Purple-Grid-Title">
              Raymond Li
              <div style={{float:"right"}}>
                <div className="Purple-Grid-Contact">
                  raymond.li3@student.kpu.ca <div style={{float:"right"}}>778-991-8398</div><br></br>
                  <a href="https://github.com/RaymondLi1" style={{color:"white"}}>https://github.com/RaymondLi1</a><br></br>
                  <a href="https://www.linkedin.com/in/raymond-li-21571927a/" style={{color:"white"}}>https://www.linkedin.com/in/raymond-li-21571927a/</a><br></br>
                </div>
              </div>

            <ColoredLine color="white" />
            </div>
            <div className="Purple-Grid-Info">

              Kwantlen Polytechnic University<br></br>
              Bachelor's of Technology: Information Technology, <br></br>
              4th Year Student (2017 - Present)
            </div>
            </Paper>
        </Grid>

                <Grid item xs={6} md={3}
                      alignItems="center"
                      justifyContent="center">
                  <img src={KennethMelee} className="gif"/>
                </Grid>
                <Grid item xs={6} md={3}
                      alignItems="center"
                      justifyContent="center">
                  <img src={ninja} className="gif"/>
                </Grid>
                <Grid item xs={6} md={3}
                      alignItems="center"
                      justifyContent="center">
                  <img src={Cassandra} className="cassandra"/>
                </Grid>
                <Grid item xs={6} md={3}
                      alignItems="center"
                      justifyContent="center">
                  <img src={fox} className="fox"/>
                </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className='White-Grid-Title'>
              Education<br></br>
              <ColoredLine color="black" />
            </div>
            <div className="White-Grid-Info">
              <ul>
                <li>
                 Completed Diploma level courses. (2019)
                </li>
                <li>
                  Cisco - CCNA2 Certified (2018)
                </li>
                <li>
                  Udemy Certificate - Math For Video Games: The Fastest Way To Get Smarter At Math (2022)
                </li>
                <li>
                  Udemy Certificate - Unity Multiplayer: Intermediate C# Coding & Networking (2022)
                </li>
            </ul>
            </div>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper>
            <img src={kpu} className='fitImage'/>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <img src={UnityScreenShot} className='fitImage'/>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper>
            <div className='White-Grid-Title'>
              Coding Proficencies <br></br>
              <ColoredLine color="black" />
            </div>
            <div className='White-Grid-Info'>
              <ul>
                <li>Unity2D Development - Advanced</li>
                <li>Java - Intermediate/Advanced</li>
                <li>C++ - Intermediate</li>
                <li>C# - Intermediate/Advanced</li>
                <li>HTML/Javascript - Beginner/Intermediate</li>
                <li>MYSQL query - Intermediate/Advanced</li>
                <li>PHP - Intermediate</li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <div className='White-Grid-Title'>
              Notable Projects (gitHub link above)<br></br>
              <ColoredLine color="black" />
            </div>
            <div className='White-Grid-Info'>
              <ul>
                <li>Food Management App (JavaFX): Registered users plan shopping trip and store related information in database</li>
                <li>The Money App (Andriod Studios): Input a variety of different incomes, lump sums, deductions,
                    financial losses, etc. into a calender App to track networth at any given date
                </li>
                <li>Licoln Public Library System (HTML/PHP): Web page system utilizing phpMyAdmin database and PHP to allow
                    public users and faculty to view a library website with search capabilities and restrictive admin features
                </li>
                <li>Kenny's Adventure - Prelude (Unity 2023.3.14f1): Extensive Unity2D game project utilizing many features including:
                    2D lighting, post processing, shader graphs, state machines, scriptable objects, object pooling, JSON binary formatted
                    persistent data.
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <div className='White-Grid-Title'>
              Background/Interests <br></br>
              <ColoredLine color="black" />
            </div>
            <div className='White-Grid-Info'>
              <ul>
                <li>
                  Current occupation: Banana Grove Market Butcher/Meat Wrapper
                </li>
                <li>
                  Avid gamer since an early age: Comfortable and adatpable in competitive environments
                </li>
                <li>
                  Home Cook: Enjoys trying new recipes and techniques
                </li>
                <li>
                  2D Animations: Entry level pixel artist, looking to branch towards different styles
                </li>
                <li>
                  Health and fitness: engages in being physically active to stay in shape
                </li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
}

export default App;
